import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Earth } from '@indriver/mireska';
import Button from 'shared/ui/button/button';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { useIsRTL, useUTMRouter, useOnClickOutside } from 'shared/hooks';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/nextjs';
import { HeaderButton, LanguageSwitcherItem, LanguageSwitcherWrapper, StyledLanguagesDroplist } from './header.style';
import { LANGUAGES_LIST } from '../model';

export const LanguageSwitcher: FC = () => {
    const { locale, pathname, replace, query } = useUTMRouter();
    const [isOpen, setOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { i18n } = useTranslation();
    const { direction } = useIsRTL();

    const redirectFromDefault = async () => {
        if (locale === 'default') {
            const savedLang = localStorage.getItem('intercity_locale');
            const userLocale = navigator.language.slice(0, 2);
            const isExistSavedLocale = LANGUAGES_LIST.findIndex((language) => language.lang === savedLang) > -1;
            const isExistUserLocale = LANGUAGES_LIST.findIndex((language) => language.lang === userLocale) > -1;
            await replace(`/${isExistSavedLocale ? savedLang : isExistUserLocale ? userLocale : 'en'}${pathname}`);
        }
    };

    useEffect(() => {
        redirectFromDefault()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [locale]);

    const handleClick = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen]);

    useOnClickOutside(wrapperRef, () => setOpen(false));
    const currentLanguage = `${locale?.slice(0, 2)}`;
    const currentLanguageUpperCase = currentLanguage[0].toUpperCase() + currentLanguage.slice(1);
    const onChangeLang = useCallback(
        async (nextLang: string) => {
            sendAnalyticsEvent('web.intercity.passenger.change_language', {
                from: locale,
                to: nextLang,
            });
            localStorage.setItem('intercity_locale', currentLanguage);
            await i18n.changeLanguage(nextLang);
            setOpen(!isOpen);
        },
        [isOpen],
    );
    const onMouseLeave = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <LanguageSwitcherWrapper>
            <Button
                dir={direction}
                className={HeaderButton}
                design='secondary'
                size='s'
                prefix={<Earth size={20} />}
                onClick={handleClick}>
                {currentLanguageUpperCase}
            </Button>
            <StyledLanguagesDroplist visible={isOpen} ref={wrapperRef} onMouseLeave={onMouseLeave}>
                <div>
                    {LANGUAGES_LIST?.filter(({ lang }) => lang !== locale && lang !== 'default').map(
                        ({ lang, language }) => {
                            return (
                                <LanguageSwitcherItem key={language} onClick={async () => onChangeLang(lang)}>
                                    <Link prefetch={false} href={{ pathname, query }} locale={lang}>
                                        {language}
                                    </Link>
                                </LanguageSwitcherItem>
                            );
                        },
                    )}
                </div>
            </StyledLanguagesDroplist>
        </LanguageSwitcherWrapper>
    );
};
