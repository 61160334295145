import getConfig from 'next/config';

const { publicRuntimeConfig = {} } = getConfig();

const ENABLE_DEV = publicRuntimeConfig.ENABLE_DEV === 'true';

const LANGUAGES_LIST = [
    {
        lang: 'default',
        language: 'default',
    },
    {
        lang: 'ar',
        language: 'العربية',
    },
    {
        lang: 'ar-MA',
        language: 'العربية (Morocco)',
    },
    {
        lang: 'en',
        language: 'English',
    },
    {
        lang: 'es',
        language: 'Español',
    },
    {
        lang: 'pt',
        language: 'Português',
    },
    {
        lang: 'ur',
        language: 'اردو',
    },
    {
        lang: 'fr',
        language: 'Français',
    },
    {
        lang: 'hi',
        language: 'हिन्दी',
    },
    {
        lang: 'id',
        language: 'Indonesian',
    },
    {
        lang: 'th',
        language: 'ภาษาไทย',
    },
    {
        lang: 'ne',
        language: 'नेपाली',
    },
];

if (ENABLE_DEV) {
    LANGUAGES_LIST.push({
        lang: 'ru',
        language: 'Русский',
    });
}

export { LANGUAGES_LIST };
