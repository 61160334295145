import React, { FC, HTMLAttributes, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import {
    LogoIntercityEn,
    LogoIntercityEs,
    LogoIntercityHi,
    LogoIntercityRu,
    LogoIntercityUr,
    LogoIntercityAr,
    LogoIntercityFr,
    LogoIntercityEnOutstation,
    LogoIntercityPt,
    LogoIntercityId,
    LogoIntercityTh,
    LogoIntercityNe,
} from 'shared/ui/logo-intercity';
import Button from 'shared/ui/button/button';
import { Enter, Exit } from '@indriver/mireska';
import { useTranslation } from 'next-i18next';
import useWindowSize from 'shared/hooks/useWindowSize';
import { CookiesEnum, getRefreshToken, removeAccessToken, setCrossDomainCookie } from 'common/lib/auth';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { CookieValueTypes } from 'cookies-next/src/types';
import { useIsRTL, useUTMRouter } from 'shared/hooks';
import { GeoWrapper } from 'context/geo';
import { order } from 'common/api';
import Link from 'next/link';
import { ACTIVE_STATUSES_ORDER } from 'shared/constants/order';
import { logger } from '@indriver/elastic-logger';
import { PreviousURLContext } from 'context/prevRoute';
import * as Sentry from '@sentry/nextjs';
import { HeaderButton, HeaderContent, HeaderDesktopWrapper, HeaderWrapperStyled } from './ui/header.style';
import { LanguageSwitcher } from './ui';

export interface ILanguagesListProps {
    lang: string;
    link: string;
    language: string;
}

type TLogo = {
    [key in 'dark' | 'light']: Record<string, ReactElement>;
};

const logo: TLogo = {
    dark: {
        ru: <LogoIntercityRu />,
        en: <LogoIntercityEn />,
        en_india: <LogoIntercityEnOutstation />,
        es: <LogoIntercityEs />,
        hi: <LogoIntercityHi />,
        ur: <LogoIntercityUr />,
        ar: <LogoIntercityAr />,
        fr: <LogoIntercityFr />,
        pt: <LogoIntercityPt />,
        id: <LogoIntercityId />,
        th: <LogoIntercityTh />,
        ne: <LogoIntercityNe />,
    },
    light: {
        ru: <LogoIntercityRu light />,
        en: <LogoIntercityEn light />,
        en_india: <LogoIntercityEnOutstation light />,
        es: <LogoIntercityEs light />,
        hi: <LogoIntercityHi light />,
        ur: <LogoIntercityUr light />,
        ar: <LogoIntercityAr light />,
        fr: <LogoIntercityFr light />,
        pt: <LogoIntercityPt light />,
        id: <LogoIntercityId light />,
        th: <LogoIntercityTh light />,
        ne: <LogoIntercityNe light />,
    },
};

interface IHeaderProps {
    design: 'dark' | 'light';
    isDisabledLanguageSwitcher?: boolean;
}

const Header: FC<IHeaderProps & HTMLAttributes<HTMLHeadElement>> = ({
    design = 'dark',
    className,
    isDisabledLanguageSwitcher = false,
}) => {
    const { push, pathname, asPath } = useUTMRouter();
    const { t, i18n } = useTranslation();
    const { width } = useWindowSize();
    const geo = useContext(GeoWrapper);
    const prevRoute = useContext(PreviousURLContext);
    const { direction, isRTL } = useIsRTL();
    const [isAuth, setAuth] = useState<CookieValueTypes>(null);
    const onLogout = async () => {
        removeAccessToken();
        setCrossDomainCookie(CookiesEnum.refreshToken, '');
        setCrossDomainCookie(CookiesEnum.accessToken, '');
        setAuth(false);
        await push('/');
    };
    useEffect(() => {
        const isExistToken = getRefreshToken();
        setAuth(isExistToken);
        (async () => {
            try {
                if (isExistToken) {
                    const res = await order.getOrder();
                    if (ACTIVE_STATUSES_ORDER.includes(res?.status)) {
                        await push('/orders');
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
                logger.error({ e });
            }
        })()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [pathname, asPath]);

    const handleClick = async () => {
        setCrossDomainCookie(CookiesEnum.referrerPage, '', { maxAge: -1 });
        if (isAuth) {
            await onLogout();
        } else {
            sendAnalyticsEvent('web.intercity.passenger.login_button.click');
            await push('/auth', undefined, { shallow: true });
            prevRoute?.setPrevious(asPath);
        }
    };

    const lang = useMemo(() => (i18n?.language === 'default' ? 'en' : i18n?.language.slice(0, 2)), [i18n?.language]);

    return (
        <HeaderWrapperStyled design={design} className={className}>
            <Link href='/'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>{lang === 'en' && geo?.code === 'IN' ? logo[design].en_india : logo[design][lang]}</a>
            </Link>
            <HeaderContent design={design}>
                <HeaderDesktopWrapper>{!isDisabledLanguageSwitcher && <LanguageSwitcher />}</HeaderDesktopWrapper>

                <Button
                    aria-label={isAuth ? t('auth.logout') : t('auth.login')}
                    dir={direction}
                    className={HeaderButton}
                    style={{ padding: width >= 1024 ? '0 14.5px' : 0 }}
                    design='secondary'
                    size='s'
                    prefix={
                        isAuth ? (
                            <Exit size={20} style={{ transform: isRTL ? 'initial' : 'rotate(180deg)' }} />
                        ) : (
                            <Enter style={{ transform: isRTL ? 'rotate(180deg)' : 'initial' }} size={20} />
                        )
                    }
                    onClick={handleClick}>
                    {width >= 1024 && (isAuth ? t('auth.logout') : t('auth.login'))}
                </Button>
            </HeaderContent>
        </HeaderWrapperStyled>
    );
};

export default Header;
