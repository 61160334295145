import React, { useRef, ReactNode } from 'react';
import { HeaderWrapperStyled } from './header.style';

interface IHeaderWrapperProps {
    children: ReactNode;
    design?: 'dark' | 'light';
}

export const HeaderWrapper: React.FC<IHeaderWrapperProps> = ({ children, design = 'dark' }) => {
    const headerRef = useRef(null);

    return (
        <HeaderWrapperStyled design={design} ref={headerRef}>
            {children}
        </HeaderWrapperStyled>
    );
};
